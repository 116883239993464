<template>

	<div class="Customer_Information_form custom-modal-wrap">
		<el-dialog :title="modal_title" :visible.sync="shows" @closed="closed" :close-on-click-modal="false"  :close-on-press-escape="false">
			<div class="form_box">

				<div class="form_flex">
					<div class="lefts">
						节假日名称：
					</div>
					<div class="rights">
						<el-input clearable="" v-model="form.title" placeholder="请输入"></el-input>
					</div>
				</div>

				<div class="form_flex">
					<div class="lefts">
						开始时间：
					</div>
					<div class="rights">
						<el-date-picker  clearable="" v-model="form.startDay" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>

				<div class="form_flex">
					<div class="lefts">
						持续时间：
					</div>
					<div class="rights2">
						<el-input  clearable="" type="number" v-model="form.duration" placeholder="请输入"></el-input>
						日
					</div>
				</div>

				<div class="btn_flex">
					<div class="btns2" @click="handle_ends" v-if="!form.id">提交</div>
					<div class="btns2" @click="handle_ends" v-if="form.id">提交</div>
					<div class="btns1" @click="handle_close">取消</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	export default {
		name: "Customer_Information_form",
		props: {

		},
		data() {
			return {
				modal_title: '新增节假日',
				shows: false,
				form: {
					id: 0,
					title: '',
					startDay: '',
					duration: ""
				},

			};
		},

		computed: {
			uploadActionImport() {
				let ret =
					process.env.NODE_ENV !== "production" ?
					"/api/uploadImage" :
					this.uploadPrefix + "/api/uploadImage";

				return ret;
			},

			uploadExtraData() {
				return {
					// user_id: "xxx",
					type: 1,
				};
			},

		},

		watch: {
			
		},
		
		created() {

		},

		methods: {
			closed() {
				this.form = {
					id: 0,
					name: '', //门店名称
					phone: '', //手机号
					email: '', //邮箱
					weixin: '', //微信
				}
			},
			init(row) {
				this.shows = true
				if (row) {
					this.modal_title = '编辑节假日'
					let data = row;
					this.form = {
						id: data.id || 0,
						title: data.title || '',
						startDay: data.startDay || '',
						duration: data.duration || "",
					}
					this.query_detail(row)
				} else {
					this.modal_title = '新增节假日'
				}
			},


			query_detail(row) {
				this.$api("kaoqin/getHolidayInfo", {
					id: row.id
				}, "get").then((res) => {
					if (res.code == 200) {
						let data = res.data;
						this.form = {
							id: data.id || 0,
							title: data.title || '',
							startDay: data.startDay || '',
							duration: data.duration || "",
						}
					}
				});
			},



			// 新增打开
			handle_add_open(row) {
				this.title = ''
				this.shows = true
			},
			//修改打开
			handle_modify_open(row) {
				this.title = ''
				let rows = JSON.parse(JSON.stringify(row))
				this.form = rows
				console.log('row', row)

				this.shows = true
			},
			//关闭    
			handle_close() {
				this.shows = false
			},
			//确认添加修改
			handle_ends() {
				if (!this.form.title) {
					alertErr('请输入节假日名称')
					return
				}
				if (!this.form.startDay) {
					alertErr('请输入开始时间')
					return
				}
				if (!this.form.duration) {
					alertErr('请输入持续时间')
					return
				}


				this.loading = true
				this.$api("kaoqin/saveHoliday", {
					...this.form
				}, "post").then((res) => {
					alert(res)
					this.loading = false
					if (res.code == 200) {
						this.shows = false
						this.$emit("confirm");
					} else {
						// alertErr(res.msg)
					}
				});
			},


			handleUserSearch(value) {
				console.log('处理用户输入', value)

				this.$api("queryUser", {
					name: value
				}, "get").then((res) => {
					if (res.code == 200) {
						this.user_list = res.data || []
					} else {

					}
				});
			},



			onSuccessUpload(res, file) {
				console.log("上传结果 res", res);
				console.log("上传结果 file", file);
				let {
					code,
					data,
					msg
				} = res;
				if (code == 200) {
					this.$emit("parentHandleUpload", {
						type: "上传成功",
						colName: this.col,
						file,
						res,
					});

					// this.$emit("parentHandleUploadSuccess", {
					//   res,
					//   file,
					//   colName: this.col,
					// });
				}
			},

			//移除上传
			handleRemove(file, fileList, other) {
				console.log("删除图片 file", file);
				console.log("删除图片 fileList", fileList);
				console.log("删除图片 other", other);
				// let index = this.list_upload.findIndex((item) => item.uid == file.uid);
				// this.list_upload.splice(index, 1);

				this.$emit("parentHandleUpload", {
					type: "删除成功",
					colName: this.col,
					file,
					res: file.response,
				});

				// this.$emit("parentHandleUploadDelete", {
				//   file,
				//   colName: this.col,
				// });
			},


		},



	};
</script>

<style scoped lang="less">
	.text {
		margin-left: 24px;
		font-weight: 400;
		font-size: 12px;
		color: #999999;
	}


	.custom-modal-wrap {
		border-radius: 20px;

		/deep/ .el-dialog .el-dialog__header {
			background: #fff !important;
		}
	}

	/deep/ .el-dialog__body {
		padding: 30px 50px 0 50px;
	}

	.btn_flex {
		padding: 25px 0;
		display: flex;
		justify-content: center !important;
	}


	.Customer_Information_form {
		border-radius: 20px;

		/deep/.el-dialog {
			width: 680px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}


		.form_box {
			.form_flex {
				display: flex;
				align-items: center;
				align-items: flex-start;
				margin-bottom: 16px;



				.lefts {
					text-align: right;
					line-height: 40px;
					width: 120px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;

					&:not(.no-required)::before {
						content: '*';
						display: inline-block;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 16px;
						color: #FC5F03;
					}
				}

				.rights {
					// width: 446px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border: 1px solid #D4D4D4;
					flex: 1;
					height: 40px;
					height: auto;
					background: #fff;
					border-radius: 4px 4px 4px 4px;

					input {
						padding: 0 12px;
						width: 100%;
						height: 40px;
					}

					/deep/.el-input {
						width: 100%;

						.el-input__inner {
							border: 0;
						}
					}

					.el-select {
						width: 100%;
					}
				}

				.rights2 {
					display: flex;
					align-items: center;
					// justify-content: space-between;
					// border: 1px solid #D4D4D4;
					flex: 1;
					height: 40px;
					height: auto;
					background: #fff;
					border-radius: 4px 4px 4px 4px;
					width: 244px;

					font-weight: 400;
					font-size: 14px;
					color: #888888;

					input {
						padding: 0 12px;
						width: 244px;
						height: 40px;
					}

					/deep/.el-input {
						width: 244px;
						margin-right: 10px;
					}


				}
			}

			.btn_flex {
				display: flex;
				align-items: center;
				justify-content: end;

				.btns1 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;
					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #B8B8B8;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #6A6A6A;
				}

				.btns2 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;

					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #2373C8;
					border-radius: 4px 4px 4px 4px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

	}
</style>